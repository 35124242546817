module.exports = [{
      plugin: require('/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Open Sans"]}},
    },{
      plugin: require('/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blue Pixl Ltd","short_name":"Blue Pixl","start_url":"/","background_color":"#FFFFFF","theme_color":"#58ADEA","display":"minimal-ui","icon":"media/icon.png"},
    },{
      plugin: require('/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YXD3SF9T4E"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-gtag-outbound/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-YXD3SF9T4E","head":false,"anonymize":true},
    }]

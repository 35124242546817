// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/runner/work/blue-pixl-website/blue-pixl-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-project-index-js": () => import("/home/runner/work/blue-pixl-website/blue-pixl-website/src/templates/project/index.js" /* webpackChunkName: "component---src-templates-project-index-js" */),
  "component---src-templates-privacy-policy-index-js": () => import("/home/runner/work/blue-pixl-website/blue-pixl-website/src/templates/privacy-policy/index.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-js" */),
  "component---src-pages-404-js": () => import("/home/runner/work/blue-pixl-website/blue-pixl-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/blue-pixl-website/blue-pixl-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

